import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Constant, FIELD_COMPONENT, MODULE_NAME, SortTypeEnum } from 'app/config/constants';
import { DialogCustomSortComponent } from 'app/dialog/dialog-custom-sort/dialog-custom-sort.component';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { DialogWebRefundTicketComponent } from 'app/dialog/dialog-web-refund-ticket/dialog-web-refund-ticket.component';
import { Common } from 'app/model/entity/common';
import { IHash, OptionFilter } from 'app/model/entity/sort-filter-object';
import { ApplicationDTO } from 'app/model/entity/ticket-editor/dto/application-DTO';
import { Orders } from 'app/model/entity/ticket-editor/orders';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import { MenuActionService } from 'app/service/menu-action.service';
import { TicketManagerService } from 'app/service/ticket-manager.service';
import { AppState } from 'app/store/app.state';
import _ from 'lodash';
import moment from 'moment';
import { DatePickerDirective } from 'ng2-date-picker';
import { Subscription } from 'rxjs';
import { DialogService } from '../../../service/dialog.service';
import { Order } from './../../../model/entity/ticket-editor/order';

@Component({
  selector: 'tab-sales-record',
  templateUrl: './tab-sales-record.component.html',
  styleUrls: ['./tab-sales-record.component.scss']
})
export class TabSalesRecordComponent implements OnInit, OnDestroy {
  @Input() tabSelected: number; // tab selected
  Constant = Constant;
  MODULE_NAME = MODULE_NAME;
  FIELD_COMPONENT = FIELD_COMPONENT;

  Helper = Helper;
  @ViewChild('dataContainer', { static: false }) dataContainer: ElementRef;
  /**
   * language key
   */
  languageKey: string;
  /**
   * Config for datepicker
   */
  config: any;
  subscriptions: Array<Subscription> = new Array<Subscription>(); //array subscription
  placeholderSearch: string; // Set text placeholder input search
  headerColumns: any[] = [
    { headerName: 'ticket-manager.sales-record.order-ID', property: 'orderId' },
    { headerName: 'ticket-manager.sales-record.sale-ticket-id', property: 'saleTicketId' },
    { headerName: 'ticket-manager.sales-record.ticket-type', property: 'ticketType' },
    { headerName: 'ticket-manager.sales-record.ticket-name', property: 'ticketName' },
    { headerName: 'ticket-manager.sales-record.company-name', property: 'companyName' },
    { headerName: 'ticket-manager.sales-record.app-ID', property: 'appName' },
    { headerName: 'ticket-manager.sales-record.user-ID', property: 'userId' },
    { headerName: 'ticket-manager.sales-record.purchased-at', property: 'purchasedAt' },
    { headerName: 'ticket-manager.sales-record.status-refund', property: 'status' },
    { headerName: 'ticket-manager.sales-record.sales-amount', property: 'salesAmount' }
  ]; // List header ticket filter
  tickets = []; // List ticket filter
  // Common object
  private commonObject: Common;
  /**
   * Column data for ticket information
   */
  columns = [
    'orderId',
    'saleTicketId',
    'ticketType',
    'ticketName',
    'companyName',
    'appName',
    'userId',
    'purchasedAt',
    'status',
    'salesAmount'
  ];
  listTicketType = [];
  listAppID = [];
  isSearching = false; // Status searching
  dataFilter = []; // List ticket after filter
  /**
   * Data for search sales ticket
   */
  dataSearch = {
    dateFrom: '',
    dateTo: '',
    ticketType: '',
    orderId: '',
    userId: '',
    transactionId: ''
  };
  orderSelected: Orders;
  orderDetailSelected: Order;
  comboItemSelected: any;
  dataFilterOrder: Orders[];
  dataFilterOrderConvert: Orders[];
  isSortFilter: boolean;
  isCheckAllOptionFilter: boolean;
  ordersDisplay: any[];
  columnSortFiltering: string;
  isShowPopUpSortFilter: boolean;
  lastColumnFilter: string;
  listFilterDisplay: Array<OptionFilter>;
  listFilterDisplayOriginal: Array<OptionFilter>;
  isFilter: boolean;
  isClear: boolean;
  listCurrentFilter: IHash = {};
  listSorted: any = [];

  headerColumnOrderDetail1: any = [
    {
      label: 'ticket-manager.sales-record.order-ID',
      field: 'orderId'
    },
    {
      label: 'ticket-manager.sales-record.sale-ticket-id',
      field: 'saleTicketId'
    },
    {
      label: 'ticket-manager.sales-record.ticket-type',
      field: 'ticketType'
    },
    {
      label: 'ticket-manager.sales-record.ticket-name',
      field: 'ticketName'
    },
    {
      label: 'ticket-manager.sales-record.company-name',
      field: 'companyName'
    },
    {
      label: 'ticket-manager.sales-record.app-ID',
      field: 'appName'
    },
    {
      label: 'ticket-manager.sales-record.user-ID',
      field: 'userId'
    },
    {
      label: 'ticket-manager.sales-record.payment-id',
      field: 'paymentId'
    },
    {
      label: 'ticket-manager.sales-record.purchased-at',
      field: 'purchasedAt'
    },
    {
      label: 'ticket-manager.sales-record.status-refund',
      field: 'status'
    },
    {
      label: 'ticket-manager.sales-record.receipt-number-when-purchasing',
      field: 'purchaseTransactionId'
    },
    {
      label: 'ticket-manager.sales-record.receipt-number-when-refunding',
      field: 'refundTransactionId'
    },
    {
      label: 'ticket-manager.sales-record.sale-amount',
      field: 'salesAmount'
    }
  ];
  headerColumnOrderDetail2: any = [
    {
      label: 'ticket-manager.sales-record.refund-deadline-at',
      field: 'refundDeadlineAt'
    },
    {
      label: 'ticket-manager.sales-record.refunded-at',
      field: 'refundedAt'
    }
  ];
  readonly LAST_FILTER = 'lastFilter';
  readonly IS_FILTER = 'isFilter';

  @Input() listApp: ApplicationDTO[]; // list Application
  appIdSelected: string;
  constructor(
    private translateService: TranslateService,
    private ticketManagerService: TicketManagerService,
    private menuActionService: MenuActionService,
    private dialogService: DialogService,
    private commonService: CommonService,
    public readonly store: Store<AppState>,
    private dataService: DataService,
    private cdr: ChangeDetectorRef
  ) {
    // Translate title
    this.placeholderSearch = this.translateService.instant('ticket-manager.sales-record.search');
    // Action menu
    this.subscriptions.push(
      // Export list ticket
      this.menuActionService.actionExportTicket.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketManagerComponent] && this.tabSelected == Constant.SALES_RECORD_ENUM) {
          // Nothing
        }
      }),
      // Refund web
      this.menuActionService.actionRefundTicket.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketManagerComponent] && this.tabSelected == Constant.SALES_RECORD_ENUM) {
          this.webRefund();
        }
      }),
      // Clear setting
      this.menuActionService.actionClearSetting.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketManagerComponent] && this.tabSelected == Constant.SALES_RECORD_ENUM) {
          this.clearSearch();
        }
      }),
      //Sort and filter
      this.menuActionService.actionSortFilter.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketManagerComponent] && this.tabSelected == Constant.SALES_RECORD_ENUM) {
          this.sortAndFilter();
        }
      }),
      // Get language
      this.translateService.onLangChange.subscribe(() => {
        // Translate title
        this.placeholderSearch = this.translateService.instant('ticket-manager.sales-record.search');
        // multi language date picker
        this.languageKey = this.commonService.getCommonObject().setting?.language;
        this.config = {
          showWeekNumbers: false,
          format: 'YYYY-MM-DD',
          firstDayOfWeek: 'mo',
          unSelectOnClick: false,
          locale: Helper.getLocale(this.languageKey),
          min: '2023-01-01'
        };
        if (this.ordersDisplay && this.ordersDisplay.length) {
          this.ordersDisplay = this.convertListOrder(_.cloneDeep(this.dataFilterOrder));
          this.dataFilterOrderConvert = this.convertListOrder(_.cloneDeep(this.dataFilterOrder));
        }
      })
    );
    this.commonObject = this.commonService.getCommonObject();
    this.isSortFilter = false;
    this.headerColumns.forEach(e => {
      e.isFilterBy = '';
      e.isSortBy = '';
    });
  }

  ngOnDestroy(): void {
    this.dataFilterOrder = undefined;
    this.orderSelected = undefined;
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  async ngOnInit(): Promise<void> {
    this.languageKey = this.commonObject?.setting?.language;
    this.config = {
      showWeekNumbers: false,
      format: 'YYYY-MM-DD',
      firstDayOfWeek: 'mo',
      unSelectOnClick: false,
      locale: Helper.getLocale(this.languageKey),
      min: '2023-01-01'
    };
  }

  /**
   * open date picker
   * @param picker
   * @param time
   */
  openDatePicker(picker: DatePickerDirective, time: any): void {
    picker.api.open();
    this.addPseudoSpan();
    picker.api.moveCalendarTo(time ?? moment());
  }

  /**
   * add element date picker
   */
  addPseudoSpan(): void {
    while (document.getElementById('span-new')) {
      document.getElementById('span-new').remove();
    }
    return;
  }

  /**
   * Search ticket
   * @param item
   */
  searchTicket(orderId?: string): void {
    this.isSearching = true;
    // Clear search
    if (
      !this.dataSearch['ticketType'] &&
      !this.dataSearch['dateFrom'] &&
      !this.dataSearch['dateTo'] &&
      !this.dataSearch['orderId'] &&
      !this.dataSearch['userId'] &&
      !this.dataSearch['transactionId'] &&
      (!this.appIdSelected || this.appIdSelected == '-1')
    ) {
      this.clearSearch();
      return;
    }
    if (this.dataFilterOrder && this.dataFilterOrder.length) {
      this.headerColumns.forEach(e => this.clearFilter(e.property));
    }
    this.resetColumnsSort();
    this.listSorted = [];
    let payload = {
      orderId: this.dataSearch['orderId'],
      ticketType: this.dataSearch['ticketType'],
      appId: this.appIdSelected ? (this.appIdSelected == '-1' ? '' : this.appIdSelected) : '',
      startDate: this.dataSearch['dateFrom'] ?? '',
      endDate: this.dataSearch['dateTo'] ?? '',
      userId: this.dataSearch['userId'] ?? '',
      transactionId: this.dataSearch['transactionId'] ?? ''
    };
    this.ticketManagerService.getOrders(payload).subscribe(
      data => {
        this.dataFilterOrder = data;
        this.ordersDisplay = this.convertListOrder(_.cloneDeep(this.dataFilterOrder));
        this.dataFilterOrderConvert = this.convertListOrder(_.cloneDeep(this.dataFilterOrder));
        this.cdr.detectChanges();
        if (!this.dataFilterOrder || this.dataFilterOrder.length == 0) {
          this.orderSelected = undefined;
          this.orderDetailSelected = undefined;
          this.comboItemSelected = undefined;
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: this.translateService.instant('ticket-manager.sales-record.no-data')
            }
          });
          return;
        }
        if (!orderId) {
          this.selectOrder(this.ordersDisplay[Constant.FIRST_ELEMENT_INDEX], null);
          let div = document.getElementById('dataTotalSale');
          div.scrollTop = 0;
        } else {
          this.selectOrder(
            this.dataFilterOrder.find(e => e.orderId == orderId),
            null
          );
        }
      },
      error => {
        this.dataFilterOrder = new Array<Orders>();
        this.ordersDisplay = new Array<any>();
        this.orderDetailSelected = undefined;
        this.comboItemSelected = undefined;
        this.orderSelected = undefined;
      }
    );
  }

  /**
   * Web refund
   */
  webRefund(): void {
    if (!this.orderSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-manager.sales-record.choose-ticket')
        }
      });
      return;
    }
    // Show dialog web refund
    this.dialogService.showDialog(
      DialogWebRefundTicketComponent,
      {
        data: {
          listOrderId: this.orderSelected.orderId,
          salesAmount: this.orderSelected.salesAmount
        }
      },
      result => {
        if (result) {
          this.searchTicket(result);
        }
      }
    );
  }

  /**
   * Clear search
   */
  clearSearch() {
    this.isSearching = false;
    this.dataSearch['dateFrom'] = undefined;
    this.dataSearch['dateTo'] = undefined;
    this.dataSearch['ticketType'] = '';
    this.dataSearch['orderId'] = '';
    this.dataSearch['userId'] = '';
    this.dataSearch['transactionId'] = '';
    this.appIdSelected = '-1';
    if (this.dataFilterOrder && this.dataFilterOrder.length) {
      this.headerColumns.forEach(e => this.clearFilter(e.property));
    }
    this.isClear = false;
    this.ordersDisplay = undefined;
    this.dataFilterOrder = undefined;
    this.dataFilterOrderConvert = undefined;
    this.orderSelected = undefined;
    this.orderDetailSelected = undefined;
    this.comboItemSelected = undefined;
    this.listCurrentFilter = {};
    this.lastColumnFilter = undefined;
    this.listFilterDisplay = undefined;
    this.isCheckAllOptionFilter = undefined;
    this.isSortFilter = false;
    this.columnSortFiltering = '';
    this.listSorted = [];
    this.resetColumnsSort();
  }

  /**
   *sortAndFilter
   */
  sortAndFilter() {
    this.isSortFilter = !this.isSortFilter;
    if (!this.ordersDisplay || !this.ordersDisplay.length) {
      return;
    }
    if (!this.isSortFilter) {
      this.resetSortFilter();
      if (this.dataFilterOrderConvert && this.dataFilterOrderConvert.length) {
        this.headerColumns.forEach(e => this.clearFilter(e.property));
        this.selectOrder(this.ordersDisplay[Constant.FIRST_ELEMENT_INDEX], null);
        let div = document.getElementById('dataTotalSale');
        div.scrollTop = 0;
      }
    }
  }

  /**
   * resetSortFilter
   */
  resetSortFilter() {
    this.ordersDisplay = _.cloneDeep(this.dataFilterOrderConvert);
    this.resetColumnsSort();
    this.listSorted = [];
    this.listCurrentFilter = {};
    this.lastColumnFilter = undefined;
    this.isFilter = undefined;
    this.columnSortFiltering = undefined;
  }

  /**
   * selectOrder
   * @param order
   * @param e
   * @returns
   */
  selectOrder(order: Orders, e: any): void {
    if (e?.target?.id === 'checkBoxOrder') {
      return;
    }
    this.orderSelected = order;
    this.ticketManagerService.getOrder(this.orderSelected.orderId).subscribe(data => {
      this.orderSelected.orderDetail = data;
      this.orderDetailSelected = this.orderSelected.orderDetail;
      this.comboItemSelected = data?.comboItems;
      this.dataContainer.nativeElement.scrollTop = 0;
    });
  }

  /**
   * getNameTicketType
   * @param ticketType
   * @returns
   */
  getNameTicketType(ticketType: string) {
    if (ticketType == Constant.SPECIAL_TICKET_ENUM) {
      return this.translateService.instant('ticket-manager.sales-record.tickets-according-to-plan');
    } else if (ticketType == Constant.COUPON_ENUM) {
      return this.translateService.instant('ticket-manager.sales-record.number-of-tickets');
    } else {
      return this.translateService.instant('ticket-manager.sales-record.set-ticket');
    }
  }

  /**
   * Get name app display
   * @param value nameApp
   * @returns
   */

  changeDisplay(value: String): String {
    if (!value) {
      return;
    }
    let temp = _.cloneDeep(value).toString();
    if (temp?.split('W')?.length > 10 && temp.length > 21) {
      value = value.substring(0, 21) + '...';
    } else if (value.length > 36) {
      value = value.substring(0, 36) + '...';
    }
    return value;
  }

  /**
   * displayScheduleAt
   * @param time
   * @returns
   */
  displayTime(time: string) {
    if (!time) {
      return '';
    }
    return time.substring(0, 19).replace('T', ' ');
  }

  /**
   * getTicketType
   * @param type
   * @param isReserve
   * @returns
   */
  getTicketType(type: string, isReserve?: boolean) {
    if (!type) {
      return;
    }
    switch (type) {
      case 'combo':
        return this.languageKey == 'en' ? type : 'セット券';
      case 'ticket':
        if (isReserve) {
          return this.languageKey == 'en' ? 'ticket(reservation)' : '企画券（予約あり）';
        }
        return this.languageKey == 'en' ? type : '企画券';
      case 'coupon':
        return this.languageKey == 'en' ? type : '綴り券';
      default:
        break;
    }
  }

  /**
   * getStatus
   * @param status
   * @returns
   */
  getStatus(status: string) {
    if (!status) {
      return;
    }
    switch (status) {
      // case '購入中':
      //   return 'pending';
      // case '購入済み':
      //   return 'bought';
      // case '購入失敗':
      //   return 'failed';
      // case '払戻済み':
      //   return 'refunded';
      // case 'セッションタイムアウト':
      //   return 'sessionExpired';
      case '購入済':
        return '購入';
      case '通常払戻済':
        return '払戻';
      default:
        return status;
    }
  }

  /**
   * getStatusForComboItem
   * @param status
   * @returns
   */
  getStatusForComboItem(status: string) {
    if (!status) {
      return;
    }
    switch (status) {
      case '未使用':
        return 'bought';
      case '有効化済み':
        return 'activated';
      case '使用済み':
        return 'used';
      default:
        return status;
    }
  }

  /**
   *showPopupSortFilter
   * @param title
   * @param event
   */
  showPopupSortFilter(property: string, event) {
    event.stopPropagation();
    this.isShowPopUpSortFilter = !this.isShowPopUpSortFilter;
    // if is show
    if (this.isShowPopUpSortFilter) {
      this.columnSortFiltering = property;
      this.fetchFilterData(property);
    }
  }

  /**
   * fetch data filter to pop up
   * @param property column show popup
   */
  public fetchFilterData(property: string): void {
    let isFiltered = false;
    let objectTemp = {};
    let listFilterTmp = _.cloneDeep(this.dataFilterOrderConvert);
    let listCurrentFilterClone = _.cloneDeep(this.listCurrentFilter);
    for (let filterTmp in listCurrentFilterClone) {
      if (filterTmp == property) {
        isFiltered = true;
        objectTemp[property] = listCurrentFilterClone[filterTmp].filter(e => e.isChecked);
        continue;
      }
      let filter = listCurrentFilterClone[filterTmp].filter(e => e.isChecked == true)?.map(e => e.name);
      // filter = filter.map(e=> e.name);
      listFilterTmp = listFilterTmp.filter(e => filter.includes(e[filterTmp]));
    }
    let mapProperty = [...new Set(listFilterTmp?.map(e => e[property]))];
    let mapChecked = objectTemp[property]?.map(e => e.name);
    if (isFiltered) {
      mapProperty.forEach(e => {
        if (!mapChecked.includes(e)) {
          objectTemp[property].push({
            isChecked: false,
            name: e
          });
        }
      });
      let templ = _.cloneDeep(objectTemp[property]);
      let cnt = 0;
      for (let index = 0; index < templ.length; index++) {
        if (!mapProperty.includes(templ[index].name)) {
          objectTemp[property].splice(index - cnt, 1);
          cnt++;
        }
      }
      delete listCurrentFilterClone[property];
      listCurrentFilterClone[property] = objectTemp[property];
    } else {
      objectTemp[property] = new Array<any>();
      mapProperty.forEach(e => {
        objectTemp[property].push({
          isChecked: true,
          name: e
        });
      });
      listCurrentFilterClone[property] = objectTemp[property];
    }
    this.listFilterDisplay = _.cloneDeep(listCurrentFilterClone[property]);
    this.listFilterDisplay = _.sortBy(this.listFilterDisplay, ['name']);
    this.listFilterDisplayOriginal = _.cloneDeep(this.listFilterDisplay);
    // get list memorize checked
    this.controlCheckBoxCheckAllFilter();
  }

  /**
   * sort basic
   * @param property property sorted
   * @param type type sort
   */
  public sortProperty(property: string, type: string): void {
    this.listSorted = [[property], [type]];
    this.ordersDisplay.sort(this.dynamicSortMultiple(this.listSorted));
    this.selectOrder(this.ordersDisplay[Constant.FIRST_ELEMENT_INDEX], null);
    let div = document.getElementById('dataTotalSale');
    div.scrollTop = 0;
    this.isShowPopUpSortFilter = false;
    // remove all sort of all column
    this.resetColumnsSort();
    // set columns is sorting
    let indexColumnSort = this.headerColumns.findIndex(data => data.property === property);
    this.headerColumns[indexColumnSort].isSortBy = type;
    this.headerColumns[indexColumnSort][Constant.IS_CHOSEN] = true;
  }

  /**
   * reset column sort disable in list
   */
  private resetColumnsSort(): void {
    this.headerColumns.forEach(column => {
      column[Constant.IS_CHOSEN] = false;
      column.isSortBy = Constant.EMPTY;
    });
  }

  /**
   * clear filter
   * @param property name of column clear filter
   */
  public clearFilter(property: string): void {
    const index = this.headerColumns.findIndex(e => e.property == property && e.isFilterBy);
    this.headerColumns.find(data => data.property == property).isFilterBy = Constant.EMPTY;
    this.isFilter = false;
    delete this.listCurrentFilter[property];
    if (index != -1) {
      this.filterData();
    }
  }

  /**
   * filterData
   */
  filterData() {
    let listFilterTmp = _.cloneDeep(this.dataFilterOrderConvert);
    if (Object.keys(this.listCurrentFilter).length) {
      for (let filterTmp in this.listCurrentFilter) {
        let filter = this.listCurrentFilter[filterTmp].filter(e => e.isChecked == true)?.map(e => e.name);
        listFilterTmp = listFilterTmp.filter(e => filter.includes(e[filterTmp]));
      }
    }
    this.ordersDisplay = listFilterTmp;
    this.ordersDisplay.sort(this.dynamicSortMultiple(this.listSorted));
    this.isShowPopUpSortFilter = false;
    if (this.ordersDisplay?.length) {
      this.selectOrder(this.ordersDisplay[Constant.FIRST_ELEMENT_INDEX], null);
      this.cdr.detectChanges();
      let div = document.getElementById('dataTotalSale');
      div.scrollTop = 0;
    } else {
      this.orderSelected = undefined;
      this.orderDetailSelected = undefined;
      this.comboItemSelected = undefined;
    }
  }

  /**
   * control checkBox check all filter when uncheck and checked
   */
  private controlCheckBoxCheckAllFilter(): void {
    this.isCheckAllOptionFilter = this.listFilterDisplay?.every(filter => filter.isChecked);
  }

  /***
   * getUniqueOption
   */
  public getUniqueOption = (array, property): any => {
    return _.uniqBy(array, property);
  };

  /**
   * showCustomSort
   */
  showCustomSort() {
    this.isShowPopUpSortFilter = false;

    // replace template with display 1, display 2
    let propertySorts = _.cloneDeep(this.headerColumns);
    propertySorts.forEach(e => {
      e.headerName = this.translateService.instant(e.headerName);
    });
    // show dialog custom sort
    this.dialogService.showDialog(DialogCustomSortComponent, { data: { list: [this.listSorted, propertySorts] } }, result => {
      if (result) {
        this.listSorted = result;
        for (let i = 0; i < this.headerColumns.length; i++) {
          let index = this.listSorted[0].findIndex(column => column === this.headerColumns[i]?.property);
          if (index === -1) {
            this.headerColumns[i].isSortBy = Constant.EMPTY;
          } else {
            this.headerColumns[i].isSortBy = this.listSorted[1][index];
          }
        }

        // sort
        this.ordersDisplay.sort(this.dynamicSortMultiple(this.listSorted));
        this.selectOrder(this.ordersDisplay[Constant.FIRST_ELEMENT_INDEX], null);
        let div = document.getElementById('dataTotalSale');
        div.scrollTop = 0;
        this.updateColumnCustomSort(this.headerColumns, propertySorts);
      }
    });
  }

  /**
   * set up for disable option in custom sort
   *
   * @param columnsBeforeSort
   * @param columnsAfterSort
   */
  private updateColumnCustomSort(columnsBeforeSort: any, columnsAfterSort: any): void {
    columnsAfterSort?.forEach((columnAfter, index) => {
      columnsBeforeSort[index][Constant.IS_CHOSEN] = columnAfter[Constant.IS_CHOSEN];
    });
  }

  /**
   * check select all option
   */
  public checkAllOptionFilter(): void {
    this.isCheckAllOptionFilter = !this.isCheckAllOptionFilter;
    this.listFilterDisplay.forEach(option => {
      option.isChecked = this.isCheckAllOptionFilter;
    });
  }

  /**
   * change checked
   * @param index index of option filter
   */
  public checkOptionFilter(index: number): void {
    this.listFilterDisplay[index].isChecked = !this.listFilterDisplay[index].isChecked;
    this.controlCheckBoxCheckAllFilter();
  }

  /**
   * filterOder
   * @param property
   * @param isFilterFirstTime
   * @returns
   */
  filterOder(property: string, isFilterFirstTime: boolean) {
    // do not filter all
    if (this.listFilterDisplay.every(e => !e.isChecked)) {
      this.listFilterDisplay.forEach(option => {
        option.isChecked = true;
      });
    }
    this.listCurrentFilter[property] = _.cloneDeep(this.listFilterDisplay);
    if (this.listFilterDisplay.every(data => !data.isChecked)) {
      this.isShowPopUpSortFilter = false;
      return;
    }
    const isEqual = JSON.stringify(this.listFilterDisplayOriginal) === JSON.stringify(this.listFilterDisplay);
    if (isEqual) {
      this.isShowPopUpSortFilter = false;
      return;
    }
    this.isFilter = true;
    this.headerColumns.find(data => data.property === property).isFilterBy = property;
    this.filterData();
    // this.ordersDisplay = listFilterTmp;
    this.ordersDisplay.sort(this.dynamicSortMultiple(this.listSorted));
    this.isShowPopUpSortFilter = false;
    this.controlCheckBoxCheckAllFilter();
    if (!isFilterFirstTime) {
      if (this.ordersDisplay?.length) {
        this.selectOrder(this.ordersDisplay[Constant.FIRST_ELEMENT_INDEX], null);
        this.cdr.detectChanges();
        let div = document.getElementById('dataTotalSale');
        div.scrollTop = 0;
      } else {
        this.orderSelected = undefined;
        this.orderDetailSelected = undefined;
        this.comboItemSelected = undefined;
      }
    }
  }

  /**
   * sort multiple
   * @param dataSort list properties and sort type sorted
   */
  public dynamicSortMultiple(dataSort: any): any {
    return function(object1, object2) {
      let output = 0,
        i = 0;
      while (output == 0 && i < dataSort[0]?.length) {
        let value1 = object1[dataSort[0][i]] ?? Constant.EMPTY; // dataSort[0] is list column sorted
        let value2 = object2[dataSort[0][i]] ?? Constant.EMPTY;
        if (dataSort[1][i] === SortTypeEnum.DESC) {
          // dataSort[1] is list sort type corresponding to column
          output = value1 > value2 ? -1 : value1 < value2 ? 1 : 0;
        } else {
          output = value1 > value2 ? 1 : value1 < value2 ? -1 : 0;
        }
        i++;
      }
      return output;
    };
  }

  checkObjectType(input) {
    return typeof input == 'object';
  }

  /**
   * convertListOrder
   * @param listFilterTmp
   * @returns
   */
  convertListOrder(listFilterTmp): any {
    listFilterTmp.forEach(e => {
      for (let property in e) {
        if (this.checkObjectType(e[property])) {
          e[property] = this.languageKey == 'en' ? e[property]?.en : e[property]?.ja;
        }
      }
      if (e['ticketType']) {
        e['ticketType'] = this.getTicketType(e['ticketType']);
      }
    });
    return listFilterTmp;
  }

  /**
   * getTitle
   * @returns
   */
  public getTitle(): string {
    return this.listApp?.find(app => app.appId == this.appIdSelected)?.appName;
  }
}

export interface itemObject {
  key: string;
  value: any;
}
